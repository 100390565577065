html, body, #root, .App {
  height: 100%;
}

.sigCanvas {}

.editable-row .ant-form-item-explain {
  position: absolute;
  top: 100%;
  font-size: 12px;
}

.editable-cell {
  position: relative;
}

.editable-cell-value-wrap {
  padding: 5px 12px;
  cursor: pointer;
}

.editable-row:hover .editable-cell-value-wrap {
  padding: 4px 11px;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
}

[data-theme='dark'] .editable-row:hover .editable-cell-value-wrap {
  border: 1px solid #434343;
}

.layout-sider::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

.layout-sider::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, .06);
  border-radius: 3px;
  box-shadow: inset 0 0 5px rgb(0 21 41 / 5%);
}

.layout-sider::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, .12);
  border-radius: 3px;
  box-shadow: inset 0 0 5px rgb(0 21 41 / 5%);
}

.layout-content::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

.layout-content::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, .06);
  border-radius: 3px;
  box-shadow: inset 0 0 5px rgb(0 21 41 / 5%);
}

.layout-content::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, .12);
  border-radius: 3px;
  box-shadow: inset 0 0 5px rgb(0 21 41 / 5%);
}

.ant-form-item-label>label {
  color: rgba(0, 0, 0, 0.87);
  font-weight: bold;
}

.ant-card {
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.radio-group::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

.radio-group::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0.06);
  border-radius: 3px;
  box-shadow: inset 0 0 5px rgb(0 21 41 / 5%);
}

.radio-group::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.12);
  border-radius: 3px;
  box-shadow: inset 0 0 5px rgb(0 21 41 / 5%);
}
